const RegisterView = () =>
  import(
    /*webpackChunkName: "RegisterView"*/ "../../../views/sales/RegisterView.vue"
  );

export default {
  path: "register",
  name: "register",
  alias: ["registro"],
  props: {
    email: null,
  },
  component: RegisterView,
  meta: {
    requiresAuth: false,
    requiresEntitlement: false,
    hideMenuOptions: true,
  },
};
